import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { useDeepCompareEffect } from "react-use"

import { calendarEventList } from "store/actions"
import { EventsCard } from "./EventsCard"
import { EVENT_TYPE_JOB } from "consts"
import { EventsFilter } from "./EventsFilter"

const defaultPeriod = [
  moment().startOf("month").toDate(),
  moment().endOf("month").toDate(),
]

const EventsContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [filter, setFilter] = useState({
    period: defaultPeriod,
    booker_id: null,
    is_closed: null,
  })

  const [date_start, date_end] = filter.period

  const onFilterChange = value => {
    setFilter(prevState => ({
      ...prevState,
      ...value,
    }))
  }

  useEffect(() => {
    return () => {
      dispatch(calendarEventList.resetFetchedData())
    }
  }, [])

  useDeepCompareEffect(() => {
    if (date_start && date_end) {
      fetchEvents()
    }
  }, [filter])

  const fetchEvents = () => {
    let params = {
      type: EVENT_TYPE_JOB,
      includes: ["eventClient.client"],
      date_start: moment(date_start).format("YYYY-MM-DD"),
      date_end: moment(date_end).format("YYYY-MM-DD"),
    }

    const { booker_id, is_closed } = filter
    params = {
      ...params,
      booker: booker_id,
      is_closed,
    }

    dispatch(calendarEventList.getList({ params }))
  }

  return (
    <>
      <EventsCard
        list={props.list}
        listLoading={props.listLoading}
        listError={props.listError}
        toolbar={
          <EventsFilter filter={filter} onFilterChange={onFilterChange} />
        }
      />
    </>
  )
}

EventsContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
}

const mapStateToProps = state => {
  const { list, listLoading, listError } = state.calendarEvent.list
  return {
    list,
    listLoading,
    listError,
  }
}

export const EventsContainer = connect(mapStateToProps)(
  EventsContainerComponent
)
