import React, { useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import "@fullcalendar/bootstrap/main.css"
import moment from "moment"
import { GoToDatePicker } from "./Elements"
import { useGoTo } from "./hooks"

export const FullCalendarControl = ({
  events = [],
  editable = false,
  droppable = true,
  selectable = false,
  initialView = "dayGridWeek",
  ...props
}) => {
  const { t } = useTranslation()

  const calendarRef = useRef(null)

  const {
    goToBtnConfig,
    datePickerVisible,
    onDatePickerChange,
    onDatePickerVisibleToggle,
  } = useGoTo({
    calendarRef,
  })

  return (
    <>
      <FullCalendar
        ref={FullCalendar => {
          if (props.calendarRef) {
            props.calendarRef.current = FullCalendar
          }
          calendarRef.current = FullCalendar
        }}
        plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
        handleWindowResize={true}
        themeSystem="bootstrap"
        headerToolbar={{
          left: "prev,next goTo today",
          center: "title",
          right: "dayGridMonth,dayGridWeek,dayGridDay",
        }}
        buttonText={{
          today: t("today"),
          month: t("month"),
          week: t("week"),
          day: t("day"),
        }}
        dayMaxEventRows={true}
        firstDay={1}
        initialView={initialView}
        events={events}
        editable={editable}
        droppable={droppable}
        selectable={selectable}
        customButtons={{
          goTo: {
            ...goToBtnConfig,
            id: "qwe",
          },
        }}
        {...props}
      />

      <GoToDatePicker
        isOpen={datePickerVisible}
        onChange={onDatePickerChange}
        onPopoverToggle={onDatePickerVisibleToggle}
      />
    </>
  )
}

FullCalendarControl.propTypes = {
  events: PropTypes.array,
  editable: PropTypes.bool,
  droppable: PropTypes.bool,
  selectable: PropTypes.bool,
  initialView: PropTypes.string,
  calendarRef: PropTypes.any,
}
