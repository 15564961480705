import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { Period } from "./Period"
import { ClosedState } from "./ClosedState"
import { Booker } from "./Booker"

export const EventsFilter = ({ filter, onFilterChange }) => {
  const { t } = useTranslation()

  const { period, is_closed, booker_id } = filter

  const onPeriodChange = value => {
    onFilterChange({ period: value })
  }

  const onClosedStateChange = value => {
    onFilterChange({ is_closed: value })
  }

  const onBookerChange = value => {
    onFilterChange({ booker_id: value })
  }

  return (
    <Row className={"mb-3"}>
      <Col md={3}>
        <Period value={period} onChange={onPeriodChange} />
      </Col>

      <Col md={3}>
        <ClosedState value={is_closed} onChange={onClosedStateChange} />
      </Col>

      <Col md={3}>
        <Booker value={booker_id} onChange={onBookerChange} />
      </Col>
    </Row>
  )
}

EventsFilter.propTypes = {
  filter: PropTypes.object,
  onFilterChange: PropTypes.func,
}
