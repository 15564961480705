import { all } from "redux-saga/effects"

import listSaga from "./list/saga"
import submitSaga from "./submit/saga"
import dataSaga from "./data/saga"
import removeSaga from "./remove/saga"
import modelSaga from "./model/sagas"
import filesSaga from "./files/sagas"
import editSaga from "./edit/sagas"
import expenseSaga from "./expense/sagas"
import rateSaga from "./rate/sagas"
import sendEmailSaga from "./sendEmail/saga"
import eventsEmailSendSaga from "./eventsEmailSend/saga"
import closeSaga from "./close/saga"
import reopenSaga from "./reopen/saga"
import invoiceSaga from "./invoice/sagas"
import contractSaga from "./contract/sagas"

function* calendarEventSaga() {
  yield all([
    listSaga(),
    submitSaga(),
    dataSaga(),
    removeSaga(),
    modelSaga(),
    filesSaga(),
    editSaga(),
    expenseSaga(),
    rateSaga(),
    sendEmailSaga(),
    eventsEmailSendSaga(),
    closeSaga(),
    reopenSaga(),
    invoiceSaga(),
    contractSaga(),
  ])
}

export default calendarEventSaga
