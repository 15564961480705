import React from "react"
import PropTypes from "prop-types"

import { OtherEvents } from "./OtherEvents"
import { InstallApp } from "./InstallApp"

export const CalendarActionBar = props => {
  return (
    <div className={"calendar-action-bar"}>
      <div
        className={
          "d-flex gap-4 align-items-center flex-wrap justify-content-end"
        }
      >
        <InstallApp />

        <OtherEvents onSelect={props.onEventSelect} />
      </div>
    </div>
  )
}

CalendarActionBar.propTypes = {
  onEventSelect: PropTypes.func,
}
