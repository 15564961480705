import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Combobox } from "components"
import { Label } from "reactstrap"

export const ClosedState = ({ value, onChange }) => {
  const { t } = useTranslation()

  const options = [
    {
      label: t("closed"),
      value: true,
    },
    {
      label: t("opened"),
      value: false,
    },
  ]

  return (
    <div className={"select2-container"}>
      <Label htmlFor={"events_closed_state"}>{t("state")}</Label>
      <Combobox
        id={"events_closed_state"}
        placeholder={t("state")}
        options={options}
        value={value}
        onChange={onChange}
        normalize={option => (option !== null ? option.value : null)}
        isDetermineValue
        isSearchable={false}
        isClearable
      />
    </div>
  )
}

ClosedState.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
}
