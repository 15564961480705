import React from "react"
import PropTypes from "prop-types"
import { useDeepCompareEffect } from "react-use"
import { useSelector } from "react-redux"

import { GeneralEventsCalendar } from "components"
import { EventsCalendarProvider } from "contexts"
import { useEventsCalendar } from "hooks"

export const CalendarContainer = props => {
  const { genders, group } = useSelector(
    state => state.modelPanel.panel.modelsSidebar.filters.values
  )

  const calendar = useEventsCalendar({})

  useDeepCompareEffect(() => {
    calendar.updateConfig({
      gender: [...genders].length === 1 ? [...genders][0] : null,
    })
  }, [genders])

  return (
    <EventsCalendarProvider {...calendar}>
      <GeneralEventsCalendar
        eventsFetchParams={{
          gender: [...genders].length === 1 ? [...genders] : null,
          groups: group ? [group] : null,
        }}
      />
    </EventsCalendarProvider>
  )
}

CalendarContainer.propTypes = {}
