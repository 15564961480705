/* eslint react/prop-types: off */
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { ReactTableView } from "components"
import { Date } from "./Date"
import { Title } from "./Title"

import * as HOC from "HOC"

const TableDataLoading = HOC.withTableDataLoading()

export const EventsTable = props => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const [columns, setColumns] = useState([
    {
      Header: t("id"),
      accessor: "id",
      id: "id",
      Cell: ({ value }) => value,
    },
    {
      Header: t("date"),
      accessor: "date",
      id: "date",
      Cell: ({ row }) => {
        return <Date data={row.original} />
      },
    },
    // {
    //   Header: t("title"),
    //   accessor: "title",
    //   id: "title",
    //   Cell: ({ row }) => {
    //     return <Title data={row.original} />
    //   },
    // },
    {
      Header: t("client"),
      accessor: "client",
      id: "client",
      Cell: ({ value }) => {
        return value?.name || "-"
      },
    },
    {
      Header: t("product"),
      accessor: "product",
      id: "product",
      Cell: ({ value }) => {
        return value || "-"
      },
    },
  ])

  return (
    <TableDataLoading
      isLoading={props.loading}
      isFailed={props.error}
      error={props.error}
    >
      <ReactTableView isLoading={props.loading} columns={columns} data={data} />
    </TableDataLoading>
  )
}

EventsTable.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
}
