import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Combobox } from "components"
import { Label } from "reactstrap"
import { useFetchBookers } from "hooks"

export const Booker = ({ value, onChange }) => {
  const { t } = useTranslation()

  const { data: bookers, fetchData: fetchBookers, loading } = useFetchBookers()

  useEffect(() => {
    fetchBookers({ pagination: false })
  }, [])

  return (
    <div className={"select2-container"}>
      <Label htmlFor={"events_closed_state"}>{t("booker")}</Label>
      <Combobox
        id={"booker_id"}
        placeholder={t("booker")}
        options={bookers}
        value={value}
        onChange={onChange}
        normalize={option => (option !== null ? option.id : null)}
        getOptionLabel={option => `${option.name}`}
        getOptionValue={option => option.id}
        isDetermineValue
        isClearable
        isLoading={loading}
      />
    </div>
  )
}

Booker.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
}
