import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { DateRangePickerField } from "components"

export const Period = ({ value, onChange }) => {
  const { t } = useTranslation()

  const [date_start, date_end] = value

  const invalid = useMemo(() => {
    return !(date_start || date_end)
  }, [date_start, date_end])

  return (
    <DateRangePickerField
      id={"period"}
      label={t("period")}
      placeholder={t("select-period")}
      value={value}
      onChange={onChange}
      isClearable={false}
      meta={{
        error: invalid && "field.error.period.required",
      }}
    />
  )
}

Period.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
}
