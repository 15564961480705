import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { InfoCard } from "components"
import { EventsTable } from "../EventsTable"

export const EventsCard = ({ toolbar, ...props }) => {
  const { t } = useTranslation()

  return (
    <>
      <InfoCard>
        {toolbar}

        <EventsTable
          list={props.list}
          loading={props.listLoading}
          error={props.listError}
        />
      </InfoCard>
    </>
  )
}

EventsCard.propTypes = {
  toolbar: PropTypes.element,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
}
