import {
  GENERAL_CALENDAR_ENTITY,
  EVENT_TYPE_CASTING,
  EVENT_TYPE_OPTION,
  EVENTS_CALENDAR_MODEL_DEFAULT_EVENTS_FETCH_PARAMS,
} from "../../../../consts"
import _ from "lodash"

const { MODEL, AGENCY, CLIENT } = GENERAL_CALENDAR_ENTITY

export const getEntityEventsRequestParams = (entity, entityData) => ({
  filter_by:
    entity === MODEL
      ? "fashion-model"
      : entity === AGENCY
      ? "agency"
      : entity === CLIENT
      ? "client"
      : null,
  filter_id: entityData?.id,
  includes:
    entity === MODEL
      ? EVENTS_CALENDAR_MODEL_DEFAULT_EVENTS_FETCH_PARAMS.includes
      : null,
})

export const calendarEventOrderByModelPriority = entityData => (a, b) => {
  const { id: modelId } = entityData

  const { extendedProps: eventA } = a
  const { extendedProps: eventB } = b

  const comparePriorityValue = () => {
    const itemA = _.find(eventA.fashion_models, { id: modelId })
    const itemB = _.find(eventB.fashion_models, { id: modelId })

    if (!itemA?.priority) {
      return 1
    } else if (!itemB?.priority) {
      return -1
    }

    const priorityA = itemA?.priority ? Number(itemA?.priority) : 0
    const priorityB = itemB?.priority ? Number(itemB?.priority) : 0

    return priorityA - priorityB
  }

  if (eventA.type === eventB.type && eventA.type === EVENT_TYPE_CASTING) {
    return comparePriorityValue()
  }

  if (eventA.type === eventB.type && eventA.type === EVENT_TYPE_OPTION) {
    return comparePriorityValue()
  }

  return 0
}
